import {Component} from 'react';
import HookStore from '@Core/HookStore';

export class _RegisterPostViews extends Component {

    callRegisterPostViews = (post_id) => {
        if(window.registerPostViews){
            window.registerPostViews(post_id);
        } else {
            // try waiting 2 seconds and see if registerPostViews can be fired
            setTimeout(
                function tryRegisterPostViews() {
                    if(window.registerPostViews){
                        window.registerPostViews(post_id);
                    }
                },
                2000
            );
            console.warn('registerPostViews is undefined.');
        }
    }

    componentDidMount(){

        // Enqueue the script using it's public location
        HookStore.doAction('enqueue_scripts', 'registerPostViews', `${process.env.PUBLIC_URL}/scripts/register-post-views.js`, '3.1', true);

        // Wait for everything to load before calling our function
        // HookStore.addAction('window_loaded', 'RegisterPostViews', this.callRegisterPostViews);

        HookStore.addFilter('the_post', 'RegisterPostViews', (post) => {
            
            this.callRegisterPostViews(post.id);
            return post;

        });

    }

    render(){
        return(null);
    }
}
