import React from 'react';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { footerStyles } from '@Styles/footer';

const Footer = () => {
    let n = process.env;
    const classes = footerStyles();

    return(
        <>
        <div className={classes.root}>
            <p>The information published on The Smart Wallet site has been prepared without taking into account your individual objectives, financial situation or needs.  Before acting on any information we provide, you should consider whether it is appropriate for you.</p>
        </div>
        <Box className={classes.root} display="flex" alignItems="center" justifyContent="center" p={1}>
            {(n.REACT_APP_DEFAULT_PRIVACY_POLICY_ID) ?
                <Link className={classes.link} href={`/privacy-policy`}>
                    Privacy Policy
                </Link>
            : null}

            {(n.REACT_APP_DEFAULT_TERMS_CONDITIONS_ID) ?
                <Link className={classes.link} href={`/terms`}>
                    Terms &amp; Conditions
                </Link>
            : null}
        </Box>
        </>
    );
}

export default Footer;
